<template>
    <div class="msgParent">
        <div class="fixedMenu">
            <p>通知</p>
            <div>
                <span
                        v-for="item,index in menuList"
                        :key="index"
                        :class="{active:index==activeIndex}"
                        @click="handleChangeMenu(index)"
                        v-text="item"
                ></span>
                <i class="notReadCount" v-show='unRead'>{{unRead}}</i>
                <i class="markLine" :style="{'left':markLinePositionLeft}"></i>
            </div>
        </div>
        <div class="main">
            <div class="msgContainer">
                <el-row
                        v-for="item,index in dataList.lists"
                        :key="index"
                        class="listDetail"
                        :style="{'order':item.topType===1?0:2}"
                        @click.native="handleOpenMsg(index)"
                >
                    <el-col class="msgtype" :span="2">
                        <span :class="{notRead:item.readStatus===1}"></span> {{menuList[item.msgType+1]}}
                    </el-col>
                    <el-col class="msgmain" :span="18">
                        <div v-if="item.topType === 1" class="setTop">
                            置顶
                        </div>
                        <div class="showmsg">
                            <p>{{item.title}}</p>
                            <!--                            <p :class="openMsgIndex === index?'notOpen':'isOpen'">{{item.content}}</p>-->
                            <p v-if="openMsgIndex === index" class="notOpen" v-html="item.content"></p>
                            <p v-else class="isOpen">{{item.strip_tags_content}}</p>
                        </div>
                    </el-col>
                    <el-col class="msgtime" :offset="1" :span="3">
                        <span>{{item.sendTime.split(' ')[0]}}</span>
                        <i :class="openMsgIndex === index?'el-icon-caret-top' : 'el-icon-caret-bottom'"></i>
                    </el-col>
                </el-row>
                <div class="showNone" v-show='!dataList.lists'>暂无消息</div>
            </div>
            <div class="paging" v-show='dataList.total>pageMsgCount'>
                <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="currentPage"
                        :page-sizes="[10, 20, 30, 40]"
                        :page-size="pageMsgCount"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="dataList.total">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
    import Paging from '../components/Paging.vue'

    export default {
        components: {
            Paging
        },
        data() {
            return {
                menuList: ['全部', '未读', '公告', '财务通知', '平台通知'],
                markLinePositionArr: ['-3px', '55px', '147px', '217px', '305px'],
                activeIndex: 0,
                currentPage: 1,
                pageMsgCount: 10,
                isOpen: false,
                openMsgIndex: 0,
                type: 0,
            }
        },
        computed: {
            markLinePositionLeft() {
                return this.markLinePositionArr[this.activeIndex]
            },
            dataList() {
                return this.$store.state.msgCenter.dataList
            },
            unRead() {
                return this.$store.getters['msgCenter/unReadCount']
            }
        },
        methods: {
            handleChangeMenu(i) {
                this.openMsgIndex = -1
                this.activeIndex = i
                this.currentPage = 1
                switch (i) {
                    case 0:
                        this.type = '0'
                        break
                    case 1:
                        this.type = '2'
                        break
                    case 2:
                        this.type = '1'
                        break
                    case 3:
                        this.type = '4'
                        break
                    case 4:
                        this.type = '3'
                        break
                }
                this.handleReqMsg()
            },
            handleSizeChange(val) {
                this.pageMsgCount = val
            },
            handleCurrentChange(val) {
                this.currentPage = val
                this.handleReqMsg()
            },
            handleOpenMsg(index) {
                this.openMsgIndex = index
            },
            handleReqMsg() {
                this.$store.dispatch('msgCenter/reqMsgAct', {
                    myproof: true,
                    reqArg: {
                        page: this.currentPage,
                        pageSize: this.pageMsgCoun,
                        type: this.type
                    }
                })
            }
        },
        created() {
            this.$route.query.type && (this.activeIndex = 1)
        }
    }
</script>

<style lang="less" scoped>
    @color-primary: #28A5DC;
    .el-loading-spinner {
        font-size: 80px;
    }

    .msgParent {
        position: relative;
        background: white;

        .fixedMenu {
            height: 93px;
            box-sizing: border-box;
            padding: 20px 35px 0;
            border-bottom: 1px solid #dde;
            width: calc(100% - 163px);
            position: fixed;
            top: 50px;
            left: 163px;
            background: white;
            z-index: 99;

            p {
                font-weight: 900;
                font-size: 20px;
                padding-bottom: 18px;
            }

            div {
                position: relative;

                span {
                    margin-right: 30px;
                    font-size: 14px;
                    cursor: pointer;

                    &.active {
                        color: @color-primary;
                        font-weight: 900;
                        cursor: default;
                    }
                }

                span:nth-child(2)::after {
                    content: "|";
                    color: #cdc;
                    margin-left: 30px;
                    font-weight: 400 !important;
                }

                .notReadCount {
                    position: absolute;
                    top: 5px;
                    left: 90px;
                    color: #797979;
                    font-size: 12px;
                }

                .markLine {
                    display: block;
                    width: 35px;
                    height: 3px;
                    position: absolute;
                    bottom: -7px;
                    background: @color-primary;
                    border-radius: 1.5px;
                    transition: all .5s;
                }
            }
        }

        .main {
            padding: 95px 36px 72px;

            .msgContainer {
                display: flex;
                flex-direction: column;

                .showNone {
                    width: 60px;
                    margin: 68px auto 0;
                    line-height: 30px;
                    text-align: center;
                    color: #b4aeae;
                    font-size: 14px;
                    border-bottom: .5px solid #dde;
                }
            }

            .listDetail {
                border-bottom: 0.5px solid #eee;
                padding: 20px 0;
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                cursor: pointer;
                color: #999999;
                font-size: 12px;
                transition: all .2s ease-in-out;

                &:hover {
                    background: #F5F7F9;

                    .showmsg {
                        p:nth-child(1) {
                            color: #262626;
                        }
                    }
                }

                &:last-child {
                    border-bottom: none;
                }

                .msgtype {
                    font-size: 14px;
                    padding-left: 30px;
                    box-sizing: border-box;
                    margin-top: 12px;

                    span {
                        display: inline-block;
                        width: 6px;
                        height: 6px;
                        border-radius: 50%;
                        background: #dde;
                        margin-right: 6px;

                        &.notRead {
                            background: @color-primary;
                        }
                    }
                }

                .msgmain {
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-start;
                    padding-left: 12px;

                    .setTop {
                        width: 40px;
                        background: #d5d5d6;
                        color: #fff;
                        height: 22px;
                        line-height: 22px;
                        text-align: center;
                        border-radius: 2px;
                        margin-right: 6px;
                        margin-top: 10px;
                    }

                    .showmsg {
                        width: 96%;

                        p {
                            transition: all .2s ease-in-out;
                        }

                        p:nth-child(1) {
                            font-size: 14px;
                            margin-bottom: 6px;
                        }

                        p.isOpen {
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            line-height: 20px;
                        }

                        p.notOpen {
                            overflow: auto;
                            line-height: 20px;
                        }
                    }
                }

                .msgtime {
                    text-align: right;
                    padding-right: 35px;
                    box-sizing: border-box;
                    margin-top: 12px;

                    span {
                        margin-right: 18px;
                    }

                    i {
                        color: #ccc;
                    }
                }
            }

            .paging {
                height: 72px;
                padding-right: 60px;
                box-sizing: border-box;
                border-top: 1px solid #dde;
                width: calc(100% - 163px);
                position: fixed;
                left: 163px;
                bottom: 0;
                background: white;
                z-index: 99;
                display: flex;
                justify-content: flex-end;
                align-items: center;
            }
        }
    }

</style>